import React from "react";

//  BOOTSTRAP COMPONENTS
import { Col, Card} from "react-bootstrap";
export default function PartnerCard(props) {
  return (
    <>
      <Col xl={1} lg={1} md={1} sm={1}>
        <Card className="partner_card_container">
            <Card.Img
              variant="top"
              src={props.image}
              className="partner_card_img_content"
            />
            {/* <Card.Title>{props.partnerName}</Card.Title> */}
        </Card>
      </Col>
    </>
  );
}

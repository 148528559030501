import React from 'react'
// SECTION CSS
import ".././Section.css"
// BOOTSTRAP
import { Container, Col, Row } from 'react-bootstrap'
// import Nav from '../../components/Nav/MenuBar'
import Nav from '../../components/Nav/MenuBar'
// import Footer from '../../components/Footer/Footer'
import Footer from '../../components/Footer/Footer'
// import FooterNav from '../../components/Footer/FooterNav/FooterNav'
import FooterNav from '../../components/Footer/FooterNav/FooterNav'
export default function Contact() {
    const MainHeading = (props) => {
        return (
            <div className='main_head_content_container'>
                <h4 className='main_head_content'>{props.mainHead}</h4>
            </div>
        )
    }
    const SubHeading = (props) => {
        return (
            <div className='sub_heading_content_container'>
                <h6 className='sub_heading_content'>{props.subhdng}</h6>
            </div>
        )
    }
    return (
        <>
            <header>
                <Nav />
            </header>
            {/* SECTION START */}
            <section className='section_grid_container'>
            <Container>
                <Row>
                    <Col xl={12} lg={12} md={12}>
                        <MainHeading mainHead={"Contact Us"} />
                        <SubHeading subhdng={"About Saifullah Digital Healthcare."} />
                        <p className='desc_content'><span className='span_content'>SAIFULLAH JUNAID JAMSHED <small>&nbsp;DIGITAL HEALTHCARE</small></span> is Pakistan’s top digital healthcare platform that focuses on reorganizing the local healthcare markets. It equates patients with the best doctors and allows the doctors to improve their medical services through their practice management software. Patients can use SJJ Digital Healthcare (website or programming app) for the online reservation of doctor appointments, e-consultations and online laboratory analysis. Furthermore, the doctors can maintain their appointments, make their schedules, manage medical history, and can deal with the billing process.</p>
                        <SubHeading subhdng={"Contact us"} />
                        <p className='desc_content'>You can avail the email address to send us a note and we’ll respond to you back (ASAP) As Soon As Possible.</p>
                        <SubHeading subhdng={"Regional Offices"} />
                        <SubHeading subhdng={"Karachi"} />
                        <p className='desc_content'>Address: Office# 02, 2nd Floor, Plot # 5-C, Lane 2, Shahbaz Commercial Area, Phase 6 D.H.A, Karachi, 75350</p>
                        <ul>
                            <li>
                                <p className='desc_content mx-0'><span style={{fontWeight: 'bold'}}>Email</span>: info@saifullahjunaidjamshed.com</p>
                            </li>
                            <li>
                                <p className='desc_content mx-0'><span style={{fontWeight: 'bold'}}>Email</span>: 021-35240577</p>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </Container>
            </section>
            {/* SECTION END */}
            {/* FOOTER START */}
            <footer style={{ background: "#bb1e1d" }}>
                <Container fluid>
                    <Row>
                        <Col xl={12} lg={12} md={12}>
                            <Footer />
                        </Col>
                    </Row>
                </Container>
                <FooterNav />
            </footer>
            {/* FOOTER END */}
        </>
    )
}

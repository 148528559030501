import React from "react";
// CSS
import "./Cards.css";
// BOOTSTRAP COMPONENTS
import { Col, Card } from "react-bootstrap";

export default function BookingInfoCard(props) {
  return (
    <Col xl={4} lg={4} md={6} sm={12}>
      <Card className="booking_info_card_container">
        <Card.Img variant="top" className="img_container" src={props.image} />
        <Card.Body>
          <span className="span">{props.infoStep}</span>
          <Card.Title>{props.infoTitle}</Card.Title>
          {/* <Card.Text>{props.infoDetail}</Card.Text> */}
        </Card.Body>
      </Card>
    </Col>
  );
}

import React from 'react'
// SECTION CSS
import ".././Section.css"
// BOOTSTRAP
import { Container, Col, Row } from 'react-bootstrap'
// import Nav from '../../components/Nav/MenuBar'
import Nav from '../../components/Nav/MenuBar'
// import Footer from '../../components/Footer/Footer'
import Footer from '../../components/Footer/Footer'
// import FooterNav from '../../components/Footer/FooterNav/FooterNav'
import FooterNav from '../../components/Footer/FooterNav/FooterNav'
export default function TermsOfUSe() {
    const MainHeading = (props) => {
        return (
            <div className='main_head_content_container'>
                <h4 className='main_head_content'>{props.mainHead}</h4>
            </div>
        )
    }
    const SubHeading = (props) => {
        return (
            <div className='sub_heading_content_container'>
                <h6 className='sub_heading_content'>{props.subhdng}</h6>
            </div>
        )
    }
    return (
        <>
            <header>
                <Nav />
            </header>
            {/* SECTION START */}
            <section className='section_grid_container'>
            <Container>
                <Row>
                    <Col xl={12} lg={12} md={12}>
                        <MainHeading mainHead={"TERMS And Conditions."} />
                        <p className='desc_content my-4'>The given terms and necessities form an agreement between you and <span className='span_content'>SJJ Digital healthcare</span> and apps, courtesies, and mobile applications are given by <span className='span_content'>SJJ Digital healthcare</span> in which these Terms of Service are given herewith privacy assist your use of the Services,  as a non-verified user and as a verified user. By accessing our services you agree without policy services if you do not agree with the terms of services you are not eligible to access our services.
                        </p>
                        <p className='desc_content my-4'>BY USING OR OTHERWISE ACCESSING THE SERVICES, AND/OR BY REGISTERING WITH US, YOU AGREE TO THE TERMS OF THIS AGREEMENT, INCLUDING THE INFORMATION PRACTICES DISCLOSED IN OUR PRIVACY POLICY AND THE USE RESTRICTIONS IN THE ACCEPTABLE USE POLICY, AND TO RESOLVE ANY DISPUTE THAT YOU MAY HAVE WITH US OR THE SERVICES IN PAKISTAN. IN CASE OF ANY ACCIDENT LIKE WRONG INJECTION IS INJECTED OR OPERATION DID NOT GO SUCCESSFUL IN THAT CASE YOU DON'T CLAIM HOSPITAL FOR THIS. IF YOU DO NOT AGREE WITH THIS AGREEMENT, YOU CANNOT USE THE SERVICES. Please note that we offer the Services “AS IS” and without guarantees. If you are registering an account or using the Services on behalf of an individual or entity other than yourself, you represent that you are authorized by such individual or entity to accept this Agreement on such individual’s or entity’s behalf.
                        </p>
                        <SubHeading subhdng={"1. ABOUT WEBSITE."} />
                        <p className='desc_content my-4'>Sections of the Services are readable without enlisting with us, but to take part or submit your details, you must enlist as a participant and certify the use of your information for intents of entitling us to deliver the Services and as our Privacy Policy.
                        </p>
                        <p className='desc_content my-4'>You know that some information may be given by people in the medical specialization; the supply of that Content does not make a medical professional/patient link, and does not include an opinion, medical recommendation, or diagnosis or therapy, but is given to you with finding appropriate medical care from a physician, dentist or other healthcare staff.
                        </p>
                        <SubHeading subhdng={"2. WE WON’T GIVE MEDICAL ADVICE."} />
                        <p className='desc_content my-4'>The INFORMATION that you acquire from<span className='span_content'> SJJ Digital healthcare</span>, and its workers, Service providers, sponsors, licensors, or otherwise via the Services is for informational,  and settlement purposes only. All medically connected details, including<span className='span_content'> SJJ Digital healthcare</span> blog, authorized<span className='span_content'> SJJ Digital healthcare</span> social sites, emails, and text. We do not suggest approving any precise tests, Healthcare Providers, procedures, opinions, or other data that may seem through the Services. If you depend on any Content given through the Services, you do that at your own security.
                        </p>
                        <SubHeading subhdng={"3. APPROVAL AND DECLARATION; Information Regarding SJJ Digital healthcare."} />
                        <p className='desc_content my-4'>In association with using the Site and the Services to find and make consultations with <span className='span_content'>SJJ Digital healthcare</span>, you have to know that:
                        </p>
                        <p className='desc_content my-4'>You are by yourself answerable for the selection of a medic specialist.
                        </p>
                        <p className='desc_content my-4'><span className='span_content'>SJJ Digital healthcare</span> operates suitable recommendations to make sure that Healthcare staff only partake in the Services if they hold all active certificates attain by law to give the services presented by them. SJJ Digit discretional healthcare may ban healthcare staff, that have been seen as nonprofessional acts.
                        </p>
                        <p className='desc_content my-4'>We will suggest you with inventories or profile previews of Healthcare staff who may be suitable to give the healthcare services based on details that you deliver to us (such as personal information, GPS location, and healthcare details). In an effort to assist in the discovery of Healthcare staff and allow the ultimate selection and diversity of Healthcare staff participating in the Services, these inventories or profile previews may also be based on other standards, for example, Healthcare staff availability, recent selections by or reviews of <span className='span_content'>SJJ Digital healthcare</span> users Remember, however, to the spell that you use the Services as given by your employer, <span className='span_content'>SJJ Digital healthcare</span> may give lists and/or profile previews based also on guide understand by your employer and you.
                        </p>
                        <SubHeading subhdng={"4. THE SERVICES AND CONTENT AS INSTRUCTIVE AND ACADEMIC RESOURCES."} />
                        <p className='desc_content my-4'>We produce the Assistance obtainable as a service to clients and Healthcare staff for the objectives of providing an instructive and academic resource. We may, but have no obligation to, have Content posted through the Assistance reviewed by our editors. It is important to mention, although, that the timeliness, exactness, and completeness of any or all of the Content is not assured. Neither the writers, the editors, nor any other party involved in the preparation or serial of this assignment can assure that the Content contained herein is valid or complete, and they will not be responsible for any errors for the results attained from the use of such Content. You are encouraged to unassisted verify the Content contained herein with other sources and to seek the advice of a qualified Healthcare staff.
                        </p>
                        <SubHeading subhdng={"4.1 HEALTHCARE PROVIDER CONTENT:"} />
                        <p className='desc_content my-4'>Healthcare Provider and approach Content is planned for general reference purposes only. Healthcare Content is both equipped by the Healthcare or working staff and gathered from considerable other data sources that may not be authorized by the Healthcare. Such Content continually changes and may become out of date, inappropriate, or incorrect. Neither the Site nor <span className='span_content'>SJJ Digital healthcare</span> gives any advice or credentials certificate about any specific Healthcare Provider. You are facilitated to independently verify such Content.
                        </p>
                        <SubHeading subhdng={"4.2 Methodologies/Services:"} />
                        <p className='desc_content my-4'>The methodologies, services, and devices discussed or vended through the Services are not suitable to all people, patients, or all medical situations. Any outcomes, services, or devices represented through the Services by advertisers, supporters, and other participants of the Services, either paid or owed, are presented for your understanding and do not necessarily suggest, and we make no claims as to, safety or accurateness for any distinct individual or projection of significance, outcome or conquest.
                        </p>
                        <SubHeading subhdng={"5. Signup."} />
                        <p className='desc_content my-4'>As part of the signup process, you will give an e-mail address and create a password. These are your credentials for accessing the Services that are only available to users (. You have to keep your information private and not share your information with anyone else. You must instantly inform us if your password has been swiped or compromised by sending an email to info@saifullahjunaidjamshed.com
                        </p>
                        <SubHeading subhdng={"6. YOUR PERSONAL DETAILS."} />
                        <p className='desc_content my-4'>In mandate to enlist, you must provide basic details about yourself. Also, you may opt to voluntarily give further information in order to help from all the Services we deliver. Our Privacy Policy details how we may use, disclose and maintain your data, which may include without restriction your name, address, CNIC and contact information; insurance information; recent medical history and current necessities; billing statement; and other details that are asked By giving such details, you authorize <span className='span_content'>SJJ Digital healthcare</span>, its employees, salesclerks and others operating on its behalf to use or confide such information in accord with our Privacy Policy. Please check the Privacy Policy carefully, as your use of the Services is based on your agreement to it.
                        </p>
                        <SubHeading subhdng={"7. YOUR RESPONSIBILLITIES GENERALLY."} />
                        <p className='desc_content my-4'>Even though the Courtesies are provided free of cost, typical, customary and any other costs for any medical or affiliated services induced by Healthcare staff will involve and will be fully your responsibility. You must solve any dispute between you or any Healthcare staff appearing from any transaction hereunder directly with the Healthcare staff.
                        </p>
                        <p className='desc_content my-4'>You are answerable for all use of the Services and use of Credentials, including use by others to whom you have given your Credentials.
                        </p>
                        <p className='desc_content my-4'>In addition to our policy in these Terms of services, we may take any legal move and implement any technological measures to dissuade offenses of the restrictions hereunder and to ensure these Terms of services or our Acceptable service Policy.
                        </p>
                        <SubHeading subhdng={"8 Responsibilities of Healthcare staff."} />
                        <p className='desc_content my-4'>Regardless of whether you are an associate or whether you schedule or intend to schedule appointments through the Services if you are a Healthcare staff or other person or commodity in the healthcare or medical, you commit that:
                        </p>
                        <p className='desc_content my-4'><b>(A)</b> You will not use the Services to view, access, or otherwise use, directly or indirectly, price, availability, or additional Content for any intent other than your own personal use as a patient or prospective patient.
                        </p>
                        <p className='desc_content my-4'><b>(B)</b> You will not use the Services to establish, attempt to establish, or execute, directly or indirectly, any agreement or coordination of the costs of the price for any product or service; the sorts, frequencies, or quantities of any product or service provided; or the customer or customer categories for any product or service, or otherwise engage or attempt to engage in price-fixing, output restriction, or customer or market designations.
                        </p>
                        <p className='desc_content my-4'><b>(C)</b> You will not use the Services, word-for-word to contend in any anti-competitive, or misleading practices, or otherwise violate appropriate antitrust, competition, or CP laws, or regulations.
                        </p>
                        <SubHeading subhdng={"8.1 LINKS TO OTHER WEBSITES."} />
                        <p className='desc_content my-4'>The Services may contain links to other websites, including links given as automated search outcomes. Some of these websites may contain materials that are objectionable, illegal, or incomplete. These links are available for your convenience only and we do not inscribe these sites or the products and services they give. You agree that we are not answerable for the content or accuracy of these sites.
                        </p>
                        <SubHeading subhdng={"9. REVIEWS YOU SUBMIT."} />
                        <p className='desc_content my-4'>You will have the option to submit the review about your experiences with Healthcare staff featured through the Services, to submit questionings concerning possible medical needs, and to partake in the other interactive and community features of the Site.It is necessary that you act responsibly when submitting   Information. All Posted Information must implement with our Acceptable User Policy.
                        </p>
                        <SubHeading subhdng={"10. YOUR USE OF DETAILS."} />
                        <p className='desc_content my-4'>All of the details are confessed by us or our licensors and are encrypted by copyright, patent, and trade secret laws, other proprietary rules, and global treaties. You acknowledge that the Services and any underlying technology or software used in connection with the Services contain <span className='span_content'>SJJ Digital Healthcare</span> proprietary details.
                        </p>
                        <SubHeading subhdng={"11. TERMINATION"} />
                        <p className='desc_content my-4'>We may eliminate or ban your registration immediately, without notifying you there has been a violation of this Agreement or other policies and terms available on the website or via the Services by you or by someone using your information. We may also discard your registration for any other reason, including inactivity for an extended period. <span className='span_content'>SJJ Digital Healthcare</span> shall not be reliable to you or any third party for any elimination of your access to the Site. Furthermore, you agree not to attempt to use the Site and Services after any, deactivation or elimination. Exclusively to your inactivity, you may be permitted to register again.
                        </p>
                        <SubHeading subhdng={"12. ELECTRONIC CONTRACTING; COPYRIGHT DISPUTE."} />
                        <p className='desc_content my-4'>Your Encouraging act of utilizing the Services or enlisting for the Assistance forms your electronic autograph to this Contract, which contains our Privacy Policy, Adequate Use Policy, any Accessory Terms, and your approval to join into contracts with us electronically.
                        </p>
                        <p className='desc_content my-4'>Please revisit our Adequate Use Guideline for our copyright dispute policy.
                        </p>
                        <SubHeading subhdng={"12.1 CHANGES TO THESE TERMS OF SERIVICE"} />
                        <p className='desc_content my-4'>We may add these Terms of services and the other documents based on of the Agreement at any time, as we reasonably consider appropriate. Upon any such addition, we will notify the terms on the website; we may also attempt to notify you in some other way. We inspire you to periodically review these Terms of SERVICES. IF AT ANY TIME YOU SELECT NOT TO ACCEPT THESE TERMS OF SERVICES, INCLUDING FOLLOWING ANY SUCH MODIFICATIONS HERETO, THEN YOU MUST STOP USING THE SITE AND THE SERVICES.
                        <SubHeading subhdng={"13. LIMITATIONS OF CLAIM."} />
                        </p>
                        <p className='desc_content my-4'>No action rising under or in link with this Agreement, regardless of the form, may be brought by you more than one <b>(1)</b> year after the cause of action; actions thereafter are forever secure.
                        </p>
                        <SubHeading subhdng={"14. SELECTION OF LAW"} />
                        <p className='desc_content my-4'>This Agreement will be considered to have been entered into and shall be construed and enforced in accordance with the laws of the Islamic Republic of Pakistan as implemented to contracts made and to be performed entirely within Pakistan.
                        </p>
                        <SubHeading subhdng={"15. WHOLE AGREEMENT"} />
                        <p className='desc_content my-4'>This Agreement and any supplemental terms, policies, rules, and suggestions posted through the Services, including the Privacy Policy, the Acceptable Use Policy and the Additional Terms, based on the entire agreement between you and us and takes the place of all previous written agreements. If any part of this Agreement is held invalid or invalid, that section shall be construed in a manner consistent with applied law to reflect, as nearly as possible, the original intents of the organizations, and the remaining sections shall remain in full force and effect. The collapse of <span className='span_content'>SJJ digital Healthcare</span> to exercise or ensure any right or of this Agreement shall not be based on a dispensation of such right or provision.
                        </p>
                        <SubHeading subhdng={"16. ASSIGNMENT"} />
                        <p className='desc_content my-4'>We may designate this contract at any time, including without limit to any parent, or any facilitated company, or as part of the sale to, merger with, or other transfer of our company to another entity. You may not designate, transfer or sublicense this Agreement to anyone else and any attempt to do so in violation of this section shall be invalid.
                        </p>
                        <SubHeading subhdng={"17. ELIGIBILITY"} />
                        <p className='desc_content my-4'>You should be 18 years of age or over, to register with us or have the access to Site and the Services. If you are between the ages of 13 and 18 or the applicable legal age in your control, you can use the Site or Services only under the supervision of your parent or guardian who has agreed to the Terms of Use. The Site is organized in Pakistan and intended for Pakistani users; any use outside of Pakistan is at the users at their own risk and users are answerable for complaints with any laws applied to their use of the Services or the Site.
                        </p>
                    </Col>
                </Row>
            </Container>
            </section>
            {/* SECTION END */}
            {/* FOOTER START */}
            <footer style={{ background: "#bb1e1d" }}>
                <Container fluid>
                    <Row>
                        <Col xl={12} lg={12} md={12}>
                            <Footer />
                        </Col>
                    </Row>
                </Container>
                <FooterNav />
            </footer>
            {/* FOOTER END */}
        </>
    )
}

import React from "react";
// CSS
import "./Footer.css";
// BOOTSTRAP
import { Col, Row } from "react-bootstrap";
// IMAGES FROM ASSETS
import Logo from '../../assets/sjj-web-logo.png'
// REACT ROUTER DOM
import { Link } from 'react-router-dom';
// FONTAWESOME ICON
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faFacebook } from "@fortawesome/free-solid-svg-icons";
import { faFacebookF, faInstagram, faTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons";

// IMAGES FROM ASSETS
import logo from "../../assets/sjj-web-logo.png";
// import AppleLogo from "../../assets/apple.png";

export default function Footer() {
  return (
    <>
      <div className="footer_container">
        <Col xl={9} lg={9} md={12} sm={12}>
          <div className="footer_contact_content">
            <Col xl={12} lg={12} md={12}>
              <Row style={{justifyContent: 'center'}}>
                <Col xl={6} md={6} sm={12} className="footer_nav_grid_content">
                  <span><Link to='/about-us'>About Us</Link></span>
                  <span><Link to='/health-card'>Health Card</Link></span>
                  <span><Link to='/contact-us'>Contact Us</Link></span>
                  <span><Link to='/faqs'>FAQs</Link></span>
                  <span><Link to='/process'>Process</Link></span>
                  <span><Link to='/privacy-policy'>Privacy Policy</Link></span>
                </Col>
                <Col xl={6} md={6} sm={12} className="footer_nav_grid_content">
                  <span><Link to='/cancellation-policy'>Cancellation Policy</Link></span>
                  <span><Link to='/payment-terms'>Payment Terms</Link></span>
                  <span><Link to='/delivery-policy'>Delivery Policy</Link></span>
                  <span><Link to='/refund-policy'>Refund Policy</Link></span>
                  <span><Link to='/terms-and-conditions'>Terms and Conditions</Link></span>
                </Col>
              </Row>
            </Col>
          </div>
        </Col>
        <Col xl={3} lg={3} md={12} sm={12}>
          <ul className="footer_social_icon_container">
            <li>
              <FontAwesomeIcon icon={faFacebookF} />
            </li>
            <li>
              <FontAwesomeIcon icon={faYoutube} />
            </li>
            <li>
              <FontAwesomeIcon icon={faTwitter} />
            </li>
            <li>
              <FontAwesomeIcon icon={faInstagram} />
            </li>
          </ul>
        </Col>
      </div>
    </>
  );
}

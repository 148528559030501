import React from 'react'
// SECTION CSS
import ".././Section.css"
// BOOTSTRAP
import { Container, Col, Row } from 'react-bootstrap'
// import Nav from '../../components/Nav/MenuBar'
import Nav from '../../components/Nav/MenuBar'
// import Footer from '../../components/Footer/Footer'
import Footer from '../../components/Footer/Footer'
// import FooterNav from '../../components/Footer/FooterNav/FooterNav'
import FooterNav from '../../components/Footer/FooterNav/FooterNav'
export default function PaymentTerms() {
    const MainHeading = (props) => {
        return (
            <div className='main_head_content_container'>
                <h4 className='main_head_content'>{props.mainHead}</h4>
            </div>
        )
    }
    const SubHeading = (props) => {
        return (
            <div className='sub_heading_content_container'>
                <h6 className='sub_heading_content'>{props.subhdng}</h6>
            </div>
        )
    }
    return (
        <>
            <header>
                <Nav />
            </header>
            {/* SECTION START */}
            <section className='section_grid_container'>
            <Container>
                <Row>
                    <Col xl={12} lg={12} md={12}>
                        <MainHeading mainHead={"TERMS AND CONDITIONS FOR ONLINE PAYMENT"} />
                        <SubHeading subhdng={"Introduction:"} />
                        <p className='desc_content my-4'>Following terms and conditions apply to the customer who utilizes any of the services offered by <span className='span_content'>SAIFULLAH JUNAID JAMSHED <small>DIGITAL HEALTHCARE</small></span> through any of its websites or application by making a payment to <span className='span_content'>SJJ Digital Healthcare</span>. Kindly read the following terms and conditions attentively. By verifying payment to <span className='span_content'>SJJ Digital Healthcare</span> through the online payment mode it is treated as an endorsement of these terms and conditions. <span className='span_content'>SJJ Digital Healthcare</span> reserves all the rights to modify terms and conditions at any time without notifying you. The Client must have read the terms and conditions attentively before utilizing the Service</p>
                        <SubHeading subhdng={"Key terms:"} />
                        <p className='desc_content'>The following is a summarized key point of this service:</p>
                        <ul className='mx-2'>
                            <li>
                                <p className='desc_content mb-0 mx-0'>Payment for this Service may be made with a Credit card, Debit card, Mobile wallets, Net Banking, or any other virtual payments strategy.</p>
                            </li>
                            <li>
                                <p className='desc_content mb-0 mx-0'>Before using this Service, it is suggested that the client should make essential details about the expenditure plan payable with the Credit/Debit card used from Credit Card or the Debit Card service provider i.e. the respective Bank.</p>
                            </li>
                            <li>
                                <p className='desc_content mb-0 mx-0'>The credit card information offered at the time of using the service is processed by the expenditure gateway of the service provider and is not given to <span className='span_content'>SJJ Digital Healthcare</span>. It is the responsibility of the Customer of the service to make sure that the details entered in the related fields are accurate. It is instructed that you take and retain a copy of the transaction for record-keeping intent, which might help in the solution of any conflicts that may occur out or use of the service.</p>
                            </li>
                            <li>
                                <p className='desc_content mb-0 mx-0'>The Client consents understand and assure that his/ her data without any restrictions, information relating to debit card/ credit card/net banking transferred over the Internet may be unsuspecting to misusage, hacking, stolen, or fake and that <span className='span_content'>SJJ Digital Healthcare</span> or the Payment Service Provider has no authority on such issues.</p>
                            </li>
                            <li>
                                <p className='desc_content mb-0 mx-0'>The service is delivered using an expenditure gateway benefit provided through an assured website. However, neither the expenditure gateway service provider nor <span className='span_content'>SJJ Digital Healthcare</span> provides any guarantee, that the information provided virtually by a client is guaranteed or may be read or blocked by a third party. <span className='span_content'>SJJ Digital Healthcare</span> does not tolerate or consider any penalty in the possibility of such unauthorized tackle, hacking, or further unauthorized permit to attributes given by a consumer of the assistance.</p>
                            </li>
                            <li>
                                <p className='desc_content mb-0 mx-0'><span className='span_content'>SJJ Digital Healthcare</span> or the Expenditure Benefit Providers shall not be responsible for any mistake misconception or postpone in, or deletion of (a) any data, information, or statement, or (b) the communication or conveyance of any such data, information, or statement; or (c) any impairment originating from or created by any such mistake, error, postpone or deletion, negligence or interruption in any details or statement. Under no affairs shall the <span className='span_content'>SJJ Digital Healthcare</span> or the Expenditure Service Providers, its workers, managers, and its third-party vendors concerned in processing, providing, or handling the Services, be accountable for any immediate, indirect, unexpected, special, or significant impairments, or any impairments whatsoever, including disciplinary or moral originating out of or in any form associated with the requirement of or any inadequacy or imperfection in the requirement of the Services or resulting from unauthorized entry or change of communications of data or originating from break or stop of the Service. </p>
                            </li>
                            <li>
                                <p className='desc_content mb-0 mx-0'>The Client consents that or any of its employees will not be accountable By the Client for any failure or impairments originating from your use of, or dependence upon the information organized on the Website, or any loss to capitulate with these Terms and Conditions where such loss is due to causes beyond <span className='span_content'>SJJ Digital Healthcare</span> proper command. Debit/Credit Card, Bank Account Details.</p>
                            </li>
                            <li>
                                <p className='desc_content mb-0 mx-0'>The Client consents that the debit/credit card attributes provided by him/ her for use of the aforementioned Service(s) must be valid and authentic and that the Client shall not use a Debit/ credit card, that is not legally possessed by him/ her or the use of which is not permitted by the permitted owner thereof. The Client further consents and undertakes to Deliver valid and accurate debit/credit card details.</p>
                            </li>
                            <li>
                                <p className='desc_content mb-0 mx-0'>The Client assurances agree and confirm that when he/ she forms a payment transaction and/or gives an online payment teaching and provides his/ her card/bank details.</p>
                            </li>
                            <li>
                                <p className='desc_content mb-0 mx-0'>The Customer is completely and legally entitled to use such credit/debit card, bank account for such transactions.</p>
                            </li>
                            <li>
                                <p className='desc_content mb-0 mx-0'>The Client is answerable to assure that the card/ bank account details given by him/ her are correct.</p>
                            </li>
                            <li>
                                <p className='desc_content mb-0 mx-0'>The Client allows debit of the selected card/ bank account for the payment of costs picked by such Clients along with the suitable Expenditures.</p>
                            </li>
                            <li>
                                <p className='desc_content mb-0 mx-0'>The client is responsible for assuring that acceptable credit is available on the selected card/ bank account at the time of completing the payment to allow the Payment of the dues owed or fees dues assigned by the Client inclusive of the suitable Fee.</p>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </Container>
            </section>
            {/* SECTION END */}
            {/* FOOTER START */}
            <footer style={{ background: "#bb1e1d" }}>
                <Container fluid>
                    <Row>
                        <Col xl={12} lg={12} md={12}>
                            <Footer />
                        </Col>
                    </Row>
                </Container>
                <FooterNav />
            </footer>
            {/* FOOTER END */}
        </>
    )
}

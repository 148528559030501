import React from 'react'
// SECTION CSS
import ".././Section.css"
// BOOTSTRAP
import { Container, Col, Row } from 'react-bootstrap'
// import Nav from '../../components/Nav/MenuBar'
import Nav from '../../components/Nav/MenuBar'
// import Footer from '../../components/Footer/Footer'
import Footer from '../../components/Footer/Footer'
// import FooterNav from '../../components/Footer/FooterNav/FooterNav'
import FooterNav from '../../components/Footer/FooterNav/FooterNav'
export default function FAQs() {
    const MainHeading = (props) => {
        return (
            <div className='main_head_content_container'>
                <h4 className='main_head_content'>{props.mainHead}</h4>
            </div>
        )
    }
    const SubHeading = (props) => {
        return (
            <div className='sub_heading_content_container'>
                <h6 className='sub_heading_content'>{props.subhdng}</h6>
            </div>
        )
    }
    return (
        <>
            <header>
                <Nav />
            </header>
            {/* SECTION START */}
            <section className='section_grid_container'>
            <Container>
                <Row>
                    <Col xl={12} lg={12} md={12}>
                        <MainHeading mainHead={"Frequently asked questions"} />
                        <h5 className='faq_ques_content'>Q: Is my payment secure in SAIFULLAH JUNAID JAMSHED HEALTHCARE?</h5>
                        <p className='desc_content my-2'>A: We use mediatory vendors for payment processing who assure your payment is protected according to the most delinquent safety standards.</p>
                        <h5 className='faq_ques_content'>Q: Do SJJ save our credit card information?</h5>
                        <p className='desc_content my-2'>A: No, We do not hold any data related to credit cards, online wallets, bank invoices, and any further monetary details.</p>
                        <h5 className='faq_ques_content'>Q: Is my personal information secure?</h5>
                        <p className='desc_content my-2'>A: For safety intents, we use a secure socket layer (SSL) to assure no one can access your confidential or banking details. We do not keep your banking details.</p>
                        <h5 className='faq_ques_content'>Q: What type of account should I use to pay online?</h5>
                        <p className='desc_content my-2'>A: You can use checking, savings, debit or credit cards, online wallets, and other digital payment modes to pay for your Bookings.</p>
                        <h5 className='faq_ques_content'>Q: Is there any assistance fee applicable on online payments?</h5>
                        <p className='desc_content my-2'>A: There are no extra assistance charges for making online payments for appointments.</p>
                        <h5 className='faq_ques_content'>Q: Will I get a cashback in case of cancellation?</h5>
                        <p className='desc_content my-2'>A: Please guide to the refunds section in payments terms and conditions.</p>
                        <h5 className='faq_ques_content'>Q: What type of personal information is collected?</h5>
                        <p className='desc_content my-2'>A: The following details are collected for the purpose of enrollment on our platform:</p>
                        <ul className='mx-2'>
                            <li>
                                <p className='desc_content mb-0 mx-0'>Name</p>
                            </li>
                            <li>
                                <p className='desc_content mb-0 mx-0'>Contact number</p>
                            </li>
                            <li>
                                <p className='desc_content mb-0 mx-0'>Age</p>
                            </li>
                            <li>
                                <p className='desc_content mb-0 mx-0'>Gender</p>
                            </li>
                        </ul>
                        <h5 className='faq_ques_content'>Q: When will my details collected?</h5>
                        <p className='desc_content my-2'>A: Your details will be gathered when you sign up on <span className='span_content'>SJJ Digital Healthcare</span> or the <span className='span_content'>SJJ Digital Healthcare</span> mobile app.</p>
                    </Col>
                </Row>
            </Container>
            </section>
            {/* SECTION END */}
            {/* FOOTER START */}
            <footer style={{ background: "#bb1e1d" }}>
                <Container fluid>
                    <Row>
                        <Col xl={12} lg={12} md={12}>
                            <Footer />
                        </Col>
                    </Row>
                </Container>
                <FooterNav />
            </footer>
            {/* FOOTER END */}
        </>
    )
}

import React from 'react'
// SECTION CSS
import ".././Section.css"
// BOOTSTRAP
import { Container, Col, Row } from 'react-bootstrap'
// import Nav from '../../components/Nav/MenuBar'
import Nav from '../../components/Nav/MenuBar'
// import Footer from '../../components/Footer/Footer'
import Footer from '../../components/Footer/Footer'
// import FooterNav from '../../components/Footer/FooterNav/FooterNav'
import FooterNav from '../../components/Footer/FooterNav/FooterNav'
export default function About() {
    const MainHeading = (props) => {
        return (
            <div className='main_head_content_container'>
                <h4 className='main_head_content'>{props.mainHead}</h4>
            </div>
        )
    }
    const SubHeading = (props) => {
        return (
            <div className='sub_heading_content_container'>
                <ul style={{ paddingLeft: "15px" }}>
                    <li>
                        <h6 className='sub_heading_content'>{props.subhdng}</h6>
                    </li>
                </ul>
            </div>
        )
    }
    return (
        <>
            <header>
                <Nav />
            </header>
            {/* SECTION START */}
            <section className='section_grid_container'>
                   {/* about start */}
            <Container>
                <Row>
                    <Col xl={12} lg={12} md={12}>
                        <MainHeading mainHead={"About Saifullah Junaid Jamshed Digital Health Care."} />
                        <p className='desc_content my-2'><span className='span_content'>Saifullah Junaid Jamshed <small>Digital Healthcare</small></span>&nbsp;is one of the best digital healthcare platforms in Pakistan.<span className='span_content'>&nbsp;SJJ Digital Healthcare</span>&nbsp;offers you to quickly reserve appointments or virtual consultations with the top doctors of Pakistan. Our cooperative and incredible platform allows you to select the right physician, complete information regarding our fee schedule, ratings, and appointments. </p>
                        <p className='desc_content my-2'><span className='span_content'>SAIFULLAH JUNAID JAMSHED <small>Digital Healthcare</small></span>&nbsp;aspires for a healthy Pakistan, A new Refreshing addition to our society. With our day-by-day increasing user base and an incredible system of medical staff with certified doctors.<span className='span_content'>&nbsp;SJJ Digital Healthcare</span>&nbsp;is creating new Standards in Pakistan’s Digital Healthcare Industry
                        </p>
                    </Col>
                </Row>
            </Container>
            {/* about end */}
            {/* our goal start */}
            <Container>
                <Row>
                    <Col xl={12} lg={12} md={12}>
                        <div className='our_goal_content_container'>
                            <span className='span_content' style={{color: '#bb1e1d'}}>OUR GOAL</span>
                            <h5>Our goal is to give Pakistan the best medical services in a Convenient and Effortless way.</h5>
                        </div>
                    </Col>
                </Row>
            </Container>
            {/* our goal end */}
            {/* our service start */}
            <Container>
                <Row>
                    <Col>
                        <div className='service_section'>
                            <SubHeading subhdng={"Services."} />
                            <p className='desc_content my-2'>We connect you with the best doctors in Pakistan who are experts in their relevant fields via in-person appointments or virtual consultations. You can use&nbsp;<span className='span_content'>SJJ Digital Healthcare</span>&nbsp;to quickly reserve an appointment for disease diagnoses, their best treatment, laboratory tests, or Clinic checkups.</p>
                            <SubHeading type={'service'} subhdng={'24/7 Extensive consultations'}/>
                            <SubHeading type={'service'} subhdng={'Diagnostic consultations'}/>
                            <SubHeading type={'service'} subhdng={'Clinic appointments'}/>
                            <SubHeading type={'service'} subhdng={'Medical track records'}/>
                        </div>
                    </Col>
                </Row>
            </Container>
            {/* our service end */}
            </section>
            {/* SECTION END */}
            {/* FOOTER START */}
            <footer style={{ background: "#bb1e1d" }}>
                <Container fluid>
                    <Row>
                        <Col xl={12} lg={12} md={12}>
                            <Footer />
                        </Col>
                    </Row>
                </Container>
                <FooterNav />
            </footer>
            {/* FOOTER END */}
        </>
    )
}

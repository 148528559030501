import React from "react";
// CSS
import "./FooterNav.css";
// BOOTSTRAP
import { Container, Row, Col } from "react-bootstrap";

export default function FooterNav() {
  return (
    <Container fluid>
      <Row>
        <Col xl={12} md={12} sm={12} style={{ background: "#4f5ace" }}>
          <span className="footer_copy_right_content">
          &copy; CopyRight 2023 SAIFULLAH JUNAID JAMSHED DIGITAL HEALTHCARE - All Right Reserved
          </span>
        </Col>
      </Row>
    </Container>
  );
}

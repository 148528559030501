import React from 'react'
// SECTION CSS
import ".././Section.css"
// BOOTSTRAP
import { Container, Col, Row } from 'react-bootstrap'
// import Nav from '../../components/Nav/MenuBar'
import Nav from '../../components/Nav/MenuBar'
// import Footer from '../../components/Footer/Footer'
import Footer from '../../components/Footer/Footer'
// import FooterNav from '../../components/Footer/FooterNav/FooterNav'
import FooterNav from '../../components/Footer/FooterNav/FooterNav'
import Image from 'react-bootstrap/Image'
export default function HealthCard() {
    const MainHeading = (props) => {
        return (
            <div className='main_head_content_container'>
                <h4 className='main_head_content'>{props.mainHead}</h4>
            </div>
        )
    }
    const SubHeading = (props) => {
        return (
            <div className='sub_heading_content_container'>
                <ul style={{ paddingLeft: "15px" }}>
                    <li>
                        <h6 className='sub_heading_content'>{props.subhdng}</h6>
                    </li>
                </ul>
            </div>
        )
    }
    return (
        <>
            <header>
                <Nav />
            </header>
            {/* SECTION START */}
            <section className='section_grid_container'>
                   {/* HealthCard start */}
            <Container>
                <Row>
                    <Col xl={12} lg={12} md={12}>
                        <MainHeading mainHead={"Health Card Saifullah Junaid Jamshed Digital Health Care."} />
                        <p className='desc_content my-2'><span className='span_content'>Health card various Discount Avalible <small>Digital Healthcare</small></span>&nbsp;is one of the best digital healthcare platforms in Pakistan.<span className='span_content'>&nbsp;SJJ Digital Healthcare</span>&nbsp;offers you to quickly reserve appointments or virtual consultations with the top doctors of Pakistan. Our cooperative and incredible platform allows you to select the right physician, complete information regarding our fee schedule, ratings, and appointments. </p>
                        <p className='desc_content my-2'><span className='span_content'>SAIFULLAH JUNAID JAMSHED <small>Digital Healthcare</small></span>&nbsp;aspires for a healthy Pakistan, A new Refreshing addition to our society. With our day-by-day increasing user base and an incredible system of medical staff with certified doctors.<span className='span_content'>&nbsp;SJJ Digital Healthcare</span>&nbsp;is creating new Standards in Pakistan’s Digital Healthcare Industry
                        </p>
                    </Col>
                </Row>
            </Container>
            {/* HealthCard end */}
            {/* our goal start */}
            <Container>
                <Row>
                    <Col xl={12} lg={12} md={12}>
                        <div className='our_goal_content_container'>
                            <span className='span_content'  style={{color: '#bb1e1d', }}>OUR GOAL</span>
                            <h5 class="text-center">HEALTH CARD DISCOUNT BOOK</h5>
                        </div>
                    </Col>
                </Row>
            </Container>
            {/* our goal end */}
            {/* our service start */}
            <Container>
                <Row>
                    <Col>
                        <div className='service_section '>
                        <img  style={{width: '100%', height: 'auto', marginTop: '2%' }} src="https://saifullahjunaidjamshed.com/Healthcard/01.webp"/>
                        <hr />
                        <img  style={{width: '100%', height: 'auto', marginTop: '2%' }} src="https://saifullahjunaidjamshed.com/Healthcard/02.webp"/>
                        <hr />
                        <img  style={{width: '100%', height: 'auto', marginTop: '2%' }} src="https://saifullahjunaidjamshed.com/Healthcard/03.webp"/>
                        <hr />
                        <img  style={{width: '100%', height: 'auto', marginTop: '2%' }} src="https://saifullahjunaidjamshed.com/Healthcard/04.webp"/>
                        <hr />
                        <img  style={{width: '100%', height: 'auto', marginTop: '2%' }} src="https://saifullahjunaidjamshed.com/Healthcard/05.webp"/>
                        <hr />
                        <img  style={{width: '100%', height: 'auto', marginTop: '2%' }} src="https://saifullahjunaidjamshed.com/Healthcard/06.webp "/>
                        
                        </div>
                    </Col>
                </Row>
            </Container>
            {/* our service end */}
            </section>
            {/* SECTION END */}
            {/* FOOTER START */}
            <footer style={{ background: "#bb1e1d" }}>
                <Container fluid>
                    <Row>
                        <Col xl={12} lg={12} md={12}>
                            <Footer />
                        </Col>
                    </Row>
                </Container>
                <FooterNav />
            </footer>
            {/* FOOTER END */}
        </>
    )
}

import React from 'react'
// SECTION CSS
import ".././Section.css"
// BOOTSTRAP
import { Container, Col, Row } from 'react-bootstrap'
// import Nav from '../../components/Nav/MenuBar'
import Nav from '../../components/Nav/MenuBar'
// import Footer from '../../components/Footer/Footer'
import Footer from '../../components/Footer/Footer'
// import FooterNav from '../../components/Footer/FooterNav/FooterNav'
import FooterNav from '../../components/Footer/FooterNav/FooterNav'
export default function PrivacyPolicy() {
    const MainHeading = (props) => {
        return (
            <div className='main_head_content_container'>
                <h4 className='main_head_content'>{props.mainHead}</h4>
            </div>
        )
    }
    const SubHeading = (props) => {
        return (
            <div className='sub_heading_content_container'>
                <h6 className='sub_heading_content'>{props.subhdng}</h6>
            </div>
        )
    }
    return (
        <>
            <header>
                <Nav />
            </header>
            {/* SECTION START */}
            <section className='section_grid_container'>
            <Container>
                <Row>
                    <Col xl={12} lg={12} md={12}>
                        <MainHeading mainHead={'Privacy Policy'} />
                        <p className='desc_content my-4'> We are dedicated to protecting your privacy and we take good care of patient information. This policy will be helpful for you to understand how we utilize and protect your data. If you have any queries, feel free to reach us at <span className='span_content'>SAIFULLAH JUNAID JAMSHED <small>Digital Healthcare</small></span> Thank you for choosing <span className='span_content'>SJJ Digital Healthcare.</span></p>
                        <SubHeading subhdng={'1. Introduction'} />
                        <p className='desc_content my-4'><span className='span_content'>SJJ Digital Healthcare</span> is dedicated to respecting the privacy rights of our clients, sightseers, and other users of <span className='span_content'>SJJ Digital Healthcare</span> and related websites, applications, services, and mobile applications provided by <span className='span_content'>SJJ Digital Healthcare</span> and in which this Privacy Policy is implemented. We created this Privacy Policy to provide you confidence as you use the Services and to obligate our rules to the protection of privacy. This Privacy Policy is only implemented to the Services. This Privacy Policy does not involve to any other website or virtual service that you may be able to access through the Services or any website or digital services of SJJ Healthcare business partners, each of which may have data collection, depository, and use practices and policies that may differ from this Privacy Policy.
                            BY USING THE SERVICES, YOU AGREE TO THE PRACTICES AND POLICIES OUTLINED IN THIS PRIVACY POLICY AND YOU HEREBY Agree TO THE Accumulation, USE, and SHARING OF YOUR details AS Clarified IN THIS PRIVACY POLICY. IF YOU DO NOT AGREE WITH THIS PRIVACY POLICY, YOU CANNOT USE THE SERVICES.
                        </p>
                        <SubHeading subhdng={'2. Collect of Information'} />
                        <p className='desc_content my-4'>
                            Our services need your personal data so we can fulfill your requirements. In order to get to know about patients, our websites ask for personal data such as email and contact number, your gender, DOB, National ID card, or Other Healthcare that helps us to know about you and make consultations more convenient for you.
                        </p>
                        <SubHeading subhdng={'3. Instinctive Data Collection.'} />
                        <p className='desc_content my-4'>
                            Are preprogrammed websites collect your data whenever you use the site? Which may include your IP Address
                            Your device Location
                            Browser URL
                            A piece of information that is linked with the account you’re using.
                            All of your personal data is secured with the privacy policy management of<span className='span_content'> SJJ Digital healthcare.</span>
                        </p>
                        <SubHeading subhdng={'4. How we use your information.'} />
                        <p className='desc_content my-4'>
                            We operate through information we gained from you to give you some incredible services that will add value to your life, such details are your full name, recent medical history contact number, and more, in order to give you services that you expect from us.
                            SJJ Healthcare services will send you notifications regarding your appointments and the latest updates that will be helpful for you to be attentive about your Healthcare services.
                            You will also be added to our email list in which we make sure to give every single detail about your appointments and treatments to keep you updated about our services. We make sure to keep your personal information Encrypted so that you will have the finest medical experience with us.

                        </p>
                        <SubHeading subhdng={'5. Information Sharing.'} />
                        <p className='desc_content my-4'>
                            We possibly share information that’s gained from you with Doctors and specialists and medical history in order to make schedules for you. Like if you want to get an appointment, for disease diagnoses and treatment, with a specialist we will share your medical history and personal data with the healthcare department. We also refer you to other healthcare departments for making an appointment for any emergency situation from the best health providers in Pakistan for the treatments you will select to get from our services. We may share your Data information details with our management to get the things like billing and data history or verification services are done for your convenience.
                            Your personal data is always encrypted and not shared with a third party except for any legal purpose if needed to share.

                        </p>
                        <SubHeading subhdng={'6. General Information'} />
                        <p className='desc_content my-4'>
                            If you may share your information in order to give reviews on our website that are not encrypted and visible to the public, we will recommend not to share any personal or credential information in the review or virtual interaction. Whatever you write will be visible to the public and can be used by a third person which is not possible for us to protect.
                        </p>
                        <SubHeading subhdng={'7. Information security.'} />
                        <p className='desc_content my-4'>
                            We always provide you with the best security services for your personal information.
                            We use to follow industry standards to protect your Personal details provided to us, For example, whenever you put your personal information on our website, we encrypt those details using SSL Technology.
                            We always notify you before sharing our personal details with the healthcare department which is mentioned in our privacy policies.
                        </p>
                        <SubHeading subhdng={'8. Controlling Your Personal Information'} />
                        <p className='desc_content my-4'>
                            If you are a verified user of the Services, you can change the Personal Information you have filled in your profile or change your username by logging into your account. If you want to deactivate your account, please contact us at info@saifullahjunaidjamshed.com from your account. However,
                            SJJ Healthcare has the authority to get information from any deactivated account to take actions over agreements and take other actions granted by law.
                            You must need to inform us if your account data is misappropriated or lost.
                        </p>
                        <SubHeading subhdng={'9. Linked Websites.'} />
                        <p className='desc_content my-4'>
                            The Services are linked to third-party websites with which SJJ Healthcare has no confederation. A link to any other SJJ healthcare website does not mean that we validate that website, the information you share on the non- SJJ healthcare website we are not accountable for the policies of the third-party website. We always tell you to get satisfied before you share your information with those websites. We will inform you of every single change we make in our policy via Email or other resources moreover we recommend visiting this page so you will be informed of any changes that we
                        </p>
                        <SubHeading subhdng={'10. Tools & Software.'} />
                        <p className='desc_content my-4'>
                            We use digital tools like Google Analytics and Facebook to keep route website traffic, site views, and other data to get a more efficient understanding of the user experience on our website and <span className='span_content'>SJJ digital Healthcare </span>app.
                        </p>
                        <SubHeading subhdng={'11. GPS Technologies.'} />
                        <p className='desc_content my-4'>
                            We use GPS technologies like Google Maps to know your location and suggest the best doctors for you.
                        </p>
                        <SubHeading subhdng={'12. Cookies.'} />
                        <p className='desc_content my-4'>
                            Cookies are documents based on your data collected on the website. They are organized on your recent visits to the website. We use cookies to offer you an adequate experience and give you the most convenient way. Remember that you can off cookies if feel to do that, go to the privacy and security settings on your browser, for example, Google Chrome, etc., tap on “Cookies” and then select one of the given options to off cookies. Turning off cookies may have some inconvenience, like you may require to sign in every time you survey the website with that some functions may not work. Although, you will have access to reserve appointments or virtual consultations with doctors.
                        </p>
                        <SubHeading subhdng={'13. Google Ads'} />
                        <p className='desc_content my-4'>
                            Google has provided policies for the use of ads to support a satisfying and secure user experience. Although we’re not using pop-up ads or Google AdSense on our website at this time.
                        </p>
                        <SubHeading subhdng={'14.  Secure Email '} />
                        <p className='desc_content my-4'>
                            We do not send flier emails to our customers. We gather emails to keep track of users and doctors who have signed up on<span className='span_content'> SJJ Digital Healthcare.</span> You have access use your email address to sign up on our site, and we will contact you via SMS to confirm your verification reservation of an appointment through<span className='span_content'> SJJ Digital Healthcare.</span>
                        </p>
                        <SubHeading subhdng={'15. Copyrights'} />
                        <p className='desc_content my-4'>
                            All the pictures, content, videos, representations, on the <span className='span_content'>SJJ Digital Healthcare</span> website, and the  <span className='span_content'>SJJ Digital Healthcare</span> mobile app is the intellectual property of <span className='span_content'>SJJ Digital Healthcare.</span> Consequently, the unauthorized use of information or content is against the copyright laws. Which will be applied to any person or association who illegally uses our content for their personal or economic benefit. All Rights Reserved.
                        </p>
                    </Col>
                </Row>
            </Container>
            </section>
            {/* SECTION END */}
            {/* FOOTER START */}
            <footer style={{ background: "#bb1e1d" }}>
                <Container fluid>
                    <Row>
                        <Col xl={12} lg={12} md={12}>
                            <Footer />
                        </Col>
                    </Row>
                </Container>
                <FooterNav />
            </footer>
            {/* FOOTER END */}
        </>
    )
}

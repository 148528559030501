import React from "react";
// import logo from '../../assets/Banner.jpg';
import Slider1 from '../../assets/Sliders/slider-1.jpg'
import Slider2 from '../../assets/Sliders/slider-2.jpg'
import Slider3 from '../../assets/Sliders/slider-3.jpg'
// CSS
import './Slider.css'
//  BOOTSTRAP COMPONENTS 
import { Container, Row ,Col ,Carousel } from "react-bootstrap";
export default function Slider() {
 
  return (
    <Container fluid>
      <Row>
        <Col xl={12} lg={12} md={12} className="grid_slider_content">
          {/* <div className="main_app_banner"></div>
          <img style={{width:'100%'}} src={logo} alt="Logo" /> */}
          <Carousel className="carousel_container">
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={Slider1}
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={Slider2}
                alt="Second slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={Slider3}
                alt="Third slide"
              />
            </Carousel.Item>
          </Carousel>
        </Col>
      </Row>
    </Container>
  );
}

import React from 'react'
// SECTION CSS
import ".././Section.css"
// BOOTSTRAP
import { Container, Col, Row } from 'react-bootstrap'
// import Nav from '../../components/Nav/MenuBar'
import Nav from '../../components/Nav/MenuBar'
// import Footer from '../../components/Footer/Footer'
import Footer from '../../components/Footer/Footer'
// import FooterNav from '../../components/Footer/FooterNav/FooterNav'
import FooterNav from '../../components/Footer/FooterNav/FooterNav'
export default function DeliveryPolicy() {
    const MainHeading = (props) => {
        return (
            <div className='main_head_content_container'>
                <h4 className='main_head_content'>{props.mainHead}</h4>
            </div>
        )
    }
    const SubHeading = (props) => {
        return (
            <div className='sub_heading_content_container'>
                <h6 className='sub_heading_content'>{props.subhdng}</h6>
            </div>
        )
    }
    return (
        <>
            <header>
                <Nav />
            </header>
            {/* SECTION START */}
            <section className='section_grid_container h_align'>
            <Container>
                <Row>
                    <Col xl={12} lg={12} md={12}>
                        <MainHeading mainHead={"SERVICE DELIVERY POLICY"} />
                        <p className='desc_content my-4'><span className='span_content'>The SAIFULLAH JUNAID JAMSHED <small>DIGITAL HEALTHCARE</small></span> forum gives you a virtual network that works as a marketplace where users who want Digital Healthcare services can correspond with the people, including, physicians who give the services. Selection a user makes or takes services is a conclusion made by user’s self-restraint. Individual assistance was given by a Doctor or mediatory supplier to a user should compose of an individual contract between such persons.in the matter of digital service <b>(virtual consultation)</b> given by a Doctor to provide consultations. <span className='span_content'>DIGITAL HEALTHCARE</span> and mediatory service will attempt to deliver top-notch services to users. The Virtual consultation is not a physical consultation and in the matter of virtual visits with <span className='span_content'>DIGITAL HEALTHCARE</span> staff is not accountable for any direct or indirect deprivation of users.
                        </p>
                        <p className='desc_content my-4'>YOU CONFESS THAT NEITHER <span className='span_content'>SJJ DIGITAL HEALTHCARE</span> NOR OF THEIR FACILITATES GIVE MEDICAL OR HEALTHCARE SERVICES OR OPERATES AS HEALTHCARE ASSISTANCE PROVIDER, OR OWN ANY PROCEDURE FOR MEDICAL OR HEALTHCARE SERVICES THE ARE GIVEN BY SELF-RELIANT MEDIATORY SUPPLIER AND DO NOT WORK FOR <span className='span_content'>SJJ DIGITAL HEALTHCARE</span> AND ITS FACILITATES.</p>
                    </Col>
                </Row>
            </Container>
            </section>
            {/* SECTION END */}
            {/* FOOTER START */}
            <footer style={{ background: "#bb1e1d" }}>
                <Container fluid>
                    <Row>
                        <Col xl={12} lg={12} md={12}>
                            <Footer />
                        </Col>
                    </Row>
                </Container>
                <FooterNav />
            </footer>
            {/* FOOTER END */}
        </>
    )
}

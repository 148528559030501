import React from 'react'
// SECTION CSS
import ".././Section.css"
// BOOTSTRAP
import { Container, Col, Row } from 'react-bootstrap'
// import Nav from '../../components/Nav/MenuBar'
import Nav from '../../components/Nav/MenuBar'
// import Footer from '../../components/Footer/Footer'
import Footer from '../../components/Footer/Footer'
// import FooterNav from '../../components/Footer/FooterNav/FooterNav'
import FooterNav from '../../components/Footer/FooterNav/FooterNav'
export default function Process() {
    const MainHeading = (props) => {
        return (
            <div className='main_head_content_container'>
                <h4 className='main_head_content'>{props.mainHead}</h4>
            </div>
        )
    }
    const SubHeading = (props) => {
        return (
            <div className='sub_heading_content_container'>
                <h6 className='sub_heading_content'>{props.subhdng}</h6>
            </div>
        )
    }
    return (
        <>
            <header>
                <Nav />
            </header>
            {/* SECTION START */}
            <section className='section_grid_container'>
            <Container>
                <Row>
                    <Col xl={12} lg={12} md={12}>
                        <MainHeading mainHead={"Our Review Process."} />
                        <p className='desc_content my-4'>Because <span className='span_content'>SAIFULLAH JUNAID JAMSHED <small>DIGITAL HEALTHCARE</small></span> believes in trust, and honesty, we want to give our readers with some information into that process we follow to create, review and upgrade our content.</p>
                        <p className='desc_content my-4'>You may find content regarding any specific medical condition, treatments, or specialty to be conveniently available on the internet. However, it can be hard to judge how, exact, or credible our content really is. We are well known that the validity of the content, especially in the healthcare means a lot. This is why<span className='span_content'> SJJ Digital Healthcare</span> give attentive service to create content that is accurate, and appropriate based on latest medical research. We do not mind to go the extra mile to add value in our content and make it more worthy for our readers. Our priority to ensure that the content we put out is Accurate, evidence-based, and precise.<span className='span_content'> SJJ Digital Healthcare</span> aspires to break free from the distractions of obsolete, practical, and fiction content and replace it with witness -based, trustworthy, and reliable content that is effortlessly accessible to anyone in need of better health suggestions. Read healthy, stay healthy!</p>
                        <SubHeading subhdng={'Our Goal.'}/>
                        <p className='desc_content my-4'>To generate unique, incredible, and trusted medical and healthcare content that effortlessly accessible to everyone.</p>
                        <SubHeading subhdng={'How do we create content.'}/>
                        <p className='desc_content my-4'>Our team of expert’s professionals and specialists is incredibly hard working and thorough in writing in-depth content that is <b>accurate</b>, with <b>easy understanding</b> and <b>helpful</b> for you. Our content creation is rely on the <b>latest</b> healthcare research and verified medical information sources.</p>
                        <SubHeading subhdng={'How do we review our content?'}/>
                        <p className='desc_content my-4'>Every single content we publish is checked by expert doctors and medical specialists to make sure it’s <b>unique, fact-based,</b> and without any mistakes.</p>
                        <SubHeading subhdng={'How do we update content?'}/>
                        <p className='desc_content my-4'>With the fast advancements being made every day in the area of medicine, it is unbelievable to think that any type of medical or health-related content will stay relevant for the near future. For this reason, we regularly upgrade on our previously published content and update it from time to time to maintain its good credibility.</p>
                        <SubHeading subhdng={'Who are our writers?'}/>
                        <p className='desc_content my-4'>Our writer’s team consist of <b> expert’s doctors</b> and <b>medical specialists</b> who have massive experience in their related fields and specializations. Being part of the healthcare field and working nearly with patients and their problems and insures that the content they produce is accurate and top notch. </p>
                        <SubHeading subhdng={'How do we use sources in our content?'}/>
                        <p className='desc_content my-4'>Our content is always have medical research and credible information. We are therefore very attentive in selecting our references and only cite and use <b>trusted</b> and certified data sources to build our content.</p>
                        <SubHeading subhdng={'Our writing style'}/>
                        <p className='desc_content my-4'>We gave keen importance to write content that is, <b>valuable for the reader,</b> and easy to consume. We try to get a writing way that makes our content <b>extraordinary, unique,</b> and <b>knowledgeable</b> for our readers.</p>
                        <SubHeading subhdng={'Feedback us?'}/>
                        <p className='desc_content my-4'>We love to hear from our readers. If you have a recommendation about our content you can reach us info@saifullahjunaidjamshed.com</p>
                    </Col>
                </Row>
            </Container>
            </section>
            {/* SECTION END */}
            {/* FOOTER START */}
            <footer style={{ background: "#bb1e1d" }}>
                <Container fluid>
                    <Row>
                        <Col xl={12} lg={12} md={12}>
                            <Footer />
                        </Col>
                    </Row>
                </Container>
                <FooterNav />
            </footer>
            {/* FOOTER END */}
        </>
    )
}

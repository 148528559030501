import React from 'react'
// SECTION CSS
import ".././Section.css"
// BOOTSTRAP
import { Container, Col, Row } from 'react-bootstrap'
// import Nav from '../../components/Nav/MenuBar'
import Nav from '../../components/Nav/MenuBar'
// import Footer from '../../components/Footer/Footer'
import Footer from '../../components/Footer/Footer'
// import FooterNav from '../../components/Footer/FooterNav/FooterNav'
import FooterNav from '../../components/Footer/FooterNav/FooterNav'
export default function CancellationPolicy() {
    const MainHeading = (props) => {
        return (
            <div className='main_head_content_container'>
                <h4 className='main_head_content'>{props.mainHead}</h4>
            </div>
        )
    }
    const SubHeading = (props) => {
        return (
            <div className='sub_heading_content_container'>
                <h6 className='sub_heading_content'>{props.subhdng}</h6>
            </div>
        )
    }
    return (
        <>
            <header>
                <Nav />
            </header>
            {/* SECTION START */}
            <section className='section_grid_container'>
            <Container>
                <Row>
                    <Col xl={12} lg={12} md={12}>
                        <MainHeading mainHead={"CANCELLATION POLICY"} />
                        <ol>
                            <li>
                                <p className='desc_content my-4'>Any prepaid booking, the customer can cancel his/her scheduled booking/appointment without paying charges until an hour before meeting such matters, we will refund the payment paid for booking/appointment subject to bank invoices and further reductions by mediatory.</p>
                            </li>
                            <li>
                                <p className='desc_content my-4'>Booking/appointment cancellation holds are to be taken out by the customer via the <span className='span_content'>SAIFULAH JUNAID JAMSHED <small>DIGITAL HEALTHCARE</small></span> app. We accomplish not contemplate cancelation appeals through phone or email.</p>
                            </li>
                            <li>
                                <p className='desc_content my-4'>Periodically, meetings may be canceled or delayed by the mediatory Benefit Provider (Doctor, Clinic, Hospital, Lab, etc.). Should this appear, we will try to reach and notify the customer and he/she may reschedule the meeting as per their comfort or call <span className='span_content'>SJJ Digital Healthcare</span> web/app for re-booking.</p>
                            </li>
                        </ol>
                        <ol>
                            <SubHeading subhdng={'Rescheduling of Prepaid Appointments:'} />
                            <li>
                                <p className='desc_content my-4'>A Customer may reschedule his/her meeting i.e. book an appointment for a distinct date and time; with the exact mediatory Service Provider for the exact sort of healthcare assistance, till 1 hour before the meeting.</p>
                            </li>
                            <li>
                                <p className='desc_content my-4'>All re-schedule proposals will be subject to a bank processing cost and these amounts will be used as is to the Consumer.</p>
                            </li>
                            <li>
                                <p className='desc_content my-4'>In the matter of re-scheduling, if there is any dissimilarity in the costs for a re-scheduled meeting, we will organize the differential payment from the Consumer.</p>
                            </li>
                        </ol>
                    </Col>
                </Row>
            </Container>
            </section>
            {/* SECTION END */}
            {/* FOOTER START */}
            <footer style={{ background: "#bb1e1d" }}>
                <Container fluid>
                    <Row>
                        <Col xl={12} lg={12} md={12}>
                            <Footer />
                        </Col>
                    </Row>
                </Container>
                <FooterNav />
            </footer>
            {/* FOOTER END */}
        </>
    )
}

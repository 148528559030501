import React, {useEffect ,useLayoutEffect} from 'react'
// REACT ROUTER DOM
import { BrowserRouter, Routes, Route,  useLocation } from 'react-router-dom';
// import HomePage from '../screens/HomePage/HomePage';
import HomePage from '../screens/HomePage/HomePage';
// import About from '../screens/About/About';
import About from '../screens/About/About';
// import Contact from '../screens/Contact/Contact';
import Contact from '../screens/Contact/Contact';
// import PrivacyPolicy from "../screens/Privacy-Policy/PrivacyPolicy";
import PrivacyPolicy from "../screens/Privacy-Policy/PrivacyPolicy";
// import FAQs from "../screens/FAQs/FAQs";
import FAQs from "../screens/FAQs/FAQs";
// import Process from "../screens/Process/Process";
import Process from "../screens/Process/Process";
// import PaymentTerms from "../screens/Payment-terms/PaymentTerms";
import PaymentTerms from "../screens/Payment-terms/PaymentTerms";
// import CancellationPolicy from "../screens/Cancellation-policy/CancellationPolicy";
import CancellationPolicy from "../screens/Cancellation-policy/CancellationPolicy";
// import DeliveryPolicy from "../screens/Delivery-Policy/DeliveryPolicy";
import DeliveryPolicy from "../screens/Delivery-Policy/DeliveryPolicy";
// import TermsOfUse from "../screens/Terms-of-use/TermsOfUse";
import TermsOfUse from "../screens/Terms-of-use/TermsOfUse";
// import RefundPolicy from "../screens/Refund-Policy/RefundPolicy";
import RefundPolicy from "../screens/Refund-Policy/RefundPolicy";
import HealthCard from '../screens/Health_Card/HealthCard';
export default function WebRouter() {
    const Wrapper = ({children}) => {
        const location = useLocation();
        useLayoutEffect(() => {
          document.documentElement.scrollTo(0, 0);
        }, [location.pathname]);
        return children
      } 
      
    return (
        <div>
            <BrowserRouter>
                <Wrapper>
                    <Routes>
                        <Route exact path='/' element={<HomePage />} />
                        <Route exact path='/about-us' element={<About />} />
                        <Route exact path='/contact-us' element={<Contact />} />
                        <Route exact path='/privacy-policy' element={<PrivacyPolicy />} />
                        <Route exact path='/faqs' element={<FAQs />} />
                        <Route exact path='/process' element={<Process />} />
                        <Route exact path='/payment-terms' element={<PaymentTerms />} />
                        <Route exact path='/cancellation-policy' element={<CancellationPolicy />} />
                        <Route exact path='/delivery-policy' element={<DeliveryPolicy />} />
                        <Route exact path='/terms-and-conditions' element={<TermsOfUse />} />
                        <Route exact path='/refund-policy' element={<RefundPolicy />} />
                        <Route exact path='/health-card' element={<HealthCard />} />
                    </Routes>
                </Wrapper>
            </BrowserRouter>
        </div>
    )
}

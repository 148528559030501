import React from 'react'
// SECTION CSS
import ".././Section.css"
// BOOTSTRAP
import { Container, Col, Row } from 'react-bootstrap'
// import Nav from '../../components/Nav/MenuBar'
import Nav from '../../components/Nav/MenuBar'
// import Footer from '../../components/Footer/Footer'
import Footer from '../../components/Footer/Footer'
// import FooterNav from '../../components/Footer/FooterNav/FooterNav'
import FooterNav from '../../components/Footer/FooterNav/FooterNav'
export default function RefundPolicy() {
    const MainHeading = (props) => {
        return (
            <div className='main_head_content_container'>
                <h4 className='main_head_content'>{props.mainHead}</h4>
            </div>
        )
    }
    const SubHeading = (props) => {
        return (
            <div className='sub_heading_content_container'>
                <h6 className='sub_heading_content'>{props.subhdng}</h6>
            </div>
        )
    }
    return (
        <>
            <header>
                <Nav />
            </header>
            {/* SECTION START */}
            <section className='section_grid_container'>
            <Container>
                <Row>
                    <Col xl={12} lg={12} md={12}>
                        <MainHeading mainHead={"REFUND POLICY"} />
                        <p className='desc_content my-4'>i. On the slip of a valid nullify request, as noted in clause1.1.we will begin refund of customer's advanced payments. The refunded by IBFT or by inverting the processed transaction <span className='span_content'>SJJ Digital healthcare </span>account with the app.</p>
                        <p className='desc_content my-4'>ii. Commonly, all refunds are being processed within 14 business days from the date the quick refund is originated at our end.</p>
                        <p className='desc_content my-4'>iii. Mention that any postpone is not on our accountability for the credit card to card holder's account for any excuses noted by the expenditure gateway or card holder's allocating bank.</p>
                        <p className='desc_content my-4'>iv. We will give you a reference number for contact regarding refunds.</p>
                        <p className='desc_content my-4'>v. No Refunds/cancellation appeals should be contemplated in case of payment against invoices/assistance received.</p>
                        <p className='desc_content my-4'>vi. Without any affairs, you will be refunded by cash for any cancellation.</p>
                        <SubHeading subhdng={"NO WARRANTY."} />
                        <p className='desc_content my-4'>No guarantee, term, express or indicated, is provided by <span className='span_content'>SJJ Digital Healthcare </span>in the honor of the service.</p>
                        <SubHeading subhdng={"GOVERNING LAW."} />
                        <p className='desc_content my-4'>Premier Jurisdiction and Regulations of Pakistan's Qualified courts is controlling the Terms and conditions.</p>
                        <SubHeading subhdng={"DISPUTE RESOLUTION."} />
                        <p className='desc_content my-4'>Any conflict regarding Terms and Conditions will be handed over to the mediatory Assigned by <span className='span_content'>SJJ Digital Healthcare </span>in the condition of mediatory action 1940 and any practical laws thereunder. The forum of mediatory is in Karachi.</p>
                        <SubHeading subhdng={"SECURITY."} />
                        <p className='desc_content my-4'>Payment attributes that are documented via payment gateway are secured when entered by the user.SJJ should not be answerable for any negligence done by a user making the payments from being caught on their screens by other individuals or acquired by other people while paying online.</p>
                        <SubHeading subhdng={"VARIATIONS TO THE TERMS AND CONDITIONS:"} />
                        <p className='desc_content my-4'><span className='span_content'>SJJ Digital Healthcare </span>has the right to make any changes in their terms and conditions from time to time the present interpretation is issued on the website. <span className='span_content'>SJJ Digital healthcare </span>has the privilege to reject any online payment without any explanation.</p>
                    </Col>
                </Row>
            </Container>
            </section>
            {/* SECTION END */}
            {/* FOOTER START */}
            <footer style={{ background: "#bb1e1d" }}>
                <Container fluid>
                    <Row>
                        <Col xl={12} lg={12} md={12}>
                            <Footer />
                        </Col>
                    </Row>
                </Container>
                <FooterNav />
            </footer>
            {/* FOOTER END */}
        </>
    )
}

import React from "react";
import { Col, Container, Row } from "react-bootstrap";
// CSS
import "./HomePage.css";
// FONTAWESOME ICON
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
// REACT REVEAL
import { LightSpeed, Bounce, Fade, Slide } from "react-reveal";
// MARQUEE FROM REACT FAST MARQUEE
import Marquee from "react-fast-marquee";
// IMAGES FROM ASSETS
// Booking Info Section
import InfoStep1 from "../../assets/info-step-1.png";
import InfoStep2 from "../../assets/info-step-2.png";
import InfoStep3 from "../../assets/info-step-3.png";
import InfoStep4 from "../../assets/info-step-4.png";
import InfoStep5 from "../../assets/info-step-5.png";
import InfoStep6 from "../../assets/info-step-6.png";
// Partner Section / HOSPI LOGOS
// import Partner1 from "../../assets/partner-logos/etmad-medical-center.png";
// import Partner2 from "../../assets/partner-logos/family-care.png";
// import Partner3 from "../../assets/partner-logos/hawwa-medical.png";
// import Partner4 from "../../assets/partner-logos/kmc-preview.png";
import hospital1 from "../../assets/Hosp-Logo/hassan-gen- medical.png";
import hospital2 from "../../assets/Hosp-Logo/mehmooda begum.png";
import hospital3 from "../../assets/Hosp-Logo/one step.png";
import hospital4 from "../../assets/Hosp-Logo/medicenter.png"
import hospital5 from "../../assets/Hosp-Logo/hawwa-medical.png";
import hospital6 from "../../assets/Hosp-Logo/k-health-care.png";
import hospital7 from "../../assets/Hosp-Logo/kmc.png";
import hospital8 from "../../assets/Hosp-Logo/al_hamza.png";
import hospital9 from "../../assets/Hosp-Logo/shah-medicl-center.png";
import hospital10 from "../../assets/Hosp-Logo/ms-hospital.png";
import hospital11 from "../../assets/Hosp-Logo/metro_medi.png";
import hospital12 from "../../assets/Hosp-Logo/Zia Logo.png";
import hospital13 from "../../assets/Hosp-Logo/Best medical.png";
import hospital14 from "../../assets/Hosp-Logo/bismillah.png";
import hospital15 from "../../assets/Hosp-Logo/etmad-medical.png";

// Download Section
import DownloadImg from "../../assets/download-tablet.png";
// import AndriodDLogo from "../../assets/andriod-download-logo.png"
// import IosDLogo from "../../assets/ios-download-logo.png"
import AppleSvg from "../../assets/available-on-the-app-store.svg";
import AndriodSvg from "../../assets/get-it-on-google-plyastore.svg";
// COMPONENTS...
// import MenuBar from '../../components/Nav/MenuBar'
import MenuBar from "../../components/Nav/MenuBar";
// import Slider from '../../components/Slider/Slider'
import Slider from "../../components/Slider/Slider";
// import BookingInfoCard from "../../components/Cards/BookingInfoCard";
import BookingInfoCard from "../../components/Cards/BookingInfoCard";
// import PartnerCard from "../../components/Cards/PartnerCard";
import PartnerCard from "../../components/Cards/PartnerCard";
// import FeedbackCard from "../../components/Cards/FeedbackCard";
// import FeedbackCard from "../../components/Cards/FeedbackCard";
// import DownloadCard from "../../components/Cards/DownloadCard";
import DownloadCard from "../../components/Cards/DownloadCard";
// import Footer from "../../components/Footer/Footer";
import Footer from "../../components/Footer/Footer";
// import FooterNav from "../../components/Footer/FooterNav/FooterNav";
import FooterNav from "../../components/Footer/FooterNav/FooterNav";
export default function HomePage() {
  return (
    <>
      <header>
        <MenuBar />
        <Slider />
      </header>
      {/* Booking Info section Start*/}
      <section className="home_page_booking_info_section_container p_60">
        <Container>
          <Row>
            <Col xl={12} lg={12} md={12}>
              <Col xl={12}>
                <div className="home_page_section_content">
                  <h2>Steps For Booking An Appointment</h2>
                </div>
              </Col>
              <Slide left>
                <div className="grid_section_container">
                  <BookingInfoCard
                    image={InfoStep1}
                    infoStep="1 "
                    infoTitle="Find A Doctor"
                    infoDetail="Click on find A Doctor"
                  />
                  <BookingInfoCard
                    image={InfoStep2}
                    infoStep="2 "
                    infoTitle="Specialization"
                    infoDetail="Select specialization according to your disease."
                  />
                  <BookingInfoCard
                    image={InfoStep3}
                    infoStep="3 "
                    infoTitle="Select A Doctor"
                    infoDetail="Select a doctor of your choice."
                  />
                  <BookingInfoCard
                    image={InfoStep4}
                    infoStep="4 "
                    infoTitle="Registration"
                    infoDetail="Register yourself with your name and contact to proceed to your booking."
                  />
                  <BookingInfoCard
                    image={InfoStep5}
                    infoStep="5 "
                    infoTitle="OTP Verification"
                    infoDetail="Enter verification code for successfull registration"
                  />
                  <BookingInfoCard
                    image={InfoStep6}
                    infoStep="6 "
                    infoTitle="Booking"
                    infoDetail="Select hospital, day and any available token number to successfully book an appointment."
                  />
                </div>
              </Slide>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Booking Info Section End*/}

      {/* Partner Card Section Start*/}
      {/* <section className="p_50">
        <Container>
          <Row>
            <Col xl={12} lg={12} md={12}>
              <Col xl={12}>
                <div className="home_page_section_content">
                  <h2>Our Partner's</h2>
                </div>
              </Col>
              <Slide bottom>
                <div className="grid_section_container">
                  <PartnerCard
                    image={Partner1}
                    partnerName="Etmad Medical Center"
                  />
                  <PartnerCard
                    image={Partner2}
                    partnerName="Family Care Hospital"
                  />
                  <PartnerCard
                    image={Partner3}
                    partnerName="Hawwa Medical Center"
                  />
                  <PartnerCard
                    image={Partner4}
                    partnerName="Karachi Medical Complex"
                  />
                </div>
              </Slide>
            </Col>
          </Row>
        </Container>
      </section> */}
      <section className="p_50">
        <Container fluid>
          <Row>
            <Col xl={12}>
              <Col xl={12}>
                <div className="home_page_section_content">
                  <h2>Our Partner's</h2>
                </div>
              </Col>
              <Col xl={12} sm={12}>
                <Marquee
                  speed={150}
                  pauseOnHover={true}
                  gradientWidth={50}
                  // play={false}
                >
                 <PartnerCard
                    image={hospital1}
                    // partnerName="Etmad Medical Center"
                  />
                <PartnerCard
                    image={hospital2}
                    // partnerName="Family Care Hospital"
                  />
                 <PartnerCard
                    image={hospital3}
                    // partnerName="Hawwa Medical Center"
                  />
                 <PartnerCard
                    image={hospital4}
                    // partnerName="Karachi Medical Complex"
                  />
                 <PartnerCard
                    image={hospital5}
                    // partnerName="Karachi Medical Complex"
                  />
                 <PartnerCard
                    image={hospital6}
                    // partnerName="Karachi Medical Complex"
                  />
                 <PartnerCard
                    image={hospital7}
                    // partnerName="Karachi Medical Complex"
                  />
                 <PartnerCard
                    image={hospital8}
                    // partnerName="Karachi Medical Complex"
                  />
                 <PartnerCard
                    image={hospital9}
                    // partnerName="Karachi Medical Complex"
                  />
                 <PartnerCard
                    image={hospital10}
                    // partnerName="Karachi Medical Complex"
                  />
                 <PartnerCard
                    image={hospital11}
                    // partnerName="Karachi Medical Complex"
                  />
                 <PartnerCard
                    image={hospital12}
                    // partnerName="Karachi Medical Complex"
                  />
                 <PartnerCard
                    image={hospital13}
                    // partnerName="Karachi Medical Complex"
                  />
                 <PartnerCard
                    image={hospital14}
                    // partnerName="Karachi Medical Complex"
                  />
                 <PartnerCard
                    image={hospital15}
                    // partnerName="Karachi Medical Complex"
                  />
                </Marquee>
              </Col>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Partner Card Section End*/}

      {/* Download App Section Start */}
      <section className="p_50">
        <Container>
          <Row>
            <Col xl={12} lg={12} md={12}>
              <Col xl={12}>
                <div className="home_page_section_content">
                  <h2>Download The App</h2>
                </div>
              </Col>
              <div className="home_page_download_app_container">
                <Col xl={8} lg={8} md={8}>
                  <div className="dowload_app_content_grid">
                    <div className="dowload_app_content">
                      <h5>Download The SJJ App</h5>
                      <p>
                        <LightSpeed right cascade>
                          Discover the Saifullah Junaid Jamshed Digital
                          Healthcare app, book appointments at your convenience
                          while staying safe at home. Utilize Saifullah Junaid
                          Jamshed Digital Healthcare's top-notch services from
                          all over Karachi. With the SJJ Digital Healthcare app,
                          you can consult with any specialist doctor and book
                          your lab tests from the best labs in Karachi. SJJ
                          Digital Healthcare app brings ease to your life making
                          the hustle of doctor consultation one click away.
                        </LightSpeed>
                      </p>
                    </div>
                    <div className="dowload_app_content_img">
                      <div className="download_app_btn_container">
                        <h3>
                          Download&nbsp;&nbsp;
                          <FontAwesomeIcon icon={faDownload} />
                        </h3>
                      </div>
                      <Col xl={12} lg={12} md={12} className="downlad_card_col">
                        <DownloadCard image={AndriodSvg} anchor={"https://play.google.com/store/apps/details?id=com.digitalhealthcare"}  />
                        <DownloadCard image={AppleSvg} anchor={"https://apps.apple.com/pk/app/sjj/id1606503084"} />
                      </Col>
                    </div>
                  </div>
                </Col>
                <Col xl={4} lg={4} md={4}>
                  <div className="main_app_bg_container">
                    <img src={DownloadImg} className="img-fluid" />
                  </div>
                </Col>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Download App Section End */}

      {/* Footer Start */}
      <footer style={{ background: "#b11e1d" }}>
        <Container fluid>
          <Row>
            <Col xl={12} lg={12} md={12}>
              <Footer />
            </Col>
          </Row>
        </Container>
        <FooterNav />
      </footer>
      {/* Footer End */}
    </>
  );
}

import React from "react";
import Bounce from "react-reveal";

export default function DownloadCard(props) {
  console.log("PROPS =>", props.anchor)
  return (
    <Bounce bottom>
      <div className="download_app_card_container">
        <div className="download_image_grid">
          <a href={props.anchor} target="_blank">
            <img className="apple_img" src={props.image} />
          </a>
        </div>
      </div>
    </Bounce>
  );
}

import HomePage from "./screens/HomePage/HomePage";
// import WebRouter from "./routing/WebRouter";
import WebRouter from "./routing/WebRouter";

function App() {
  return (
    <>
      <div className="main_app_container">
        <WebRouter />
      </div>
    </>
  );
}

export default App;

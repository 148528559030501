import React from "react";
import "./MenuBar.css";
// BOOTSTRAP
import { Container, Row, Col, Navbar, Nav } from "react-bootstrap";
// REACT ROUTER DOM
import { Link } from 'react-router-dom'
// IMAGES FROM ASSETS
import Logo from '../../assets/sjj-web-logo.png'
export default function MenuNav() {
  return (
    <Container fluid>
      <Row>
        <Col xl={12} className="grid_content">
          <Navbar expand="lg" className="navbar_bg_container" fixed="top">
            <Navbar.Brand className="navbar_logo_content">
              {/* <Link to='/'><div className="bg_image"></div></Link> */}
              <Link to='/'><img src={Logo} className='bg_image' /></Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
              <Nav className="menubar_nav_content">
                <Link to='/'><Nav className="nav_text">Home</Nav></Link>
                <Link to='/about-us'><Nav className="nav_text">About</Nav></Link>
                <Link to='/contact-us'><Nav className="nav_text">Contact Us</Nav></Link>
                <Link to='/health-card'><Nav className="nav_text">Health Card</Nav></Link>
                <Nav.Link href='http://sjjhospital.com/' className='nav_text' style={{marginBottom: 2}}>Login as hospital</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Col>
      </Row>
    </Container>
  );
}
